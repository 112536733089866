.popover-container {
  padding: 1rem;
  padding-right: 2rem;
  position: fixed;
  top: 20px;
  right: 0;
  border-radius: 10px 0 0 10px;
  z-index: 100;
}

.popover-success {
  background-color: #d4edd9ff;
  color: #145824ff;
}

.popover-error {
  background-color: #eeafb8ff;
  color: #d4364dff;
}

.close-button {
  font-size: 14px;
  position: absolute;
  top: 10px;
  right: 10px;
}

.close-button-success {
  color: #145824ff;
}

.close-button-error {
  color: #d4364dff;
}
